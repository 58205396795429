import { t } from '@/i18n-instance'

export const promobarCountdown = {
  init() {
    const promobarCountdowns = document.querySelectorAll('.js-b-promobar__countdown');
    if (!promobarCountdowns.length) return;

    promobarCountdowns.forEach(promobarCountdown => {
      const countDownDate = new Date(promobarCountdown.getAttribute('data-countdown')).getTime();
      const useSeconds = promobarCountdown.hasAttribute('use-seconds');
      const useAbbr = promobarCountdown.hasAttribute('use-abbr');
  
      const getResultHTML = ({ 
        useSeconds = false, 
        dateMarksMap = {
          day: 0,
          hour: 0,
          minute: 0,
          second: 0
        }
      }) => {
        const dateMarks = useSeconds 
          ? Object.keys(dateMarksMap) 
          : Object.keys(dateMarksMap).filter(mark => mark !== 'second')
        
        return dateMarks.reduce((outputHtml, currentDateMarkKey, index) => {
          const dateMark = dateMarksMap[currentDateMarkKey]
          const itemTemplate = ` 
          <div class="b-promobar__countdown-item b-promobar__countdown-item--${currentDateMarkKey}">${dateMark} <span class="b-promobar__countdown-item-desc">${
              (() => {
                if(useAbbr) return t(`date.${currentDateMarkKey}.abbr`)
                if(dateMark === 0) return t(`date.${currentDateMarkKey}.moreOrEqualToFive`)
                if(dateMark === 1) return t(`date.${currentDateMarkKey}.one`)
                if(dateMark >= 2 && dateMark <= 4) return t(`date.${currentDateMarkKey}.moreThenOneLessThanFive`)
                else return t(`date.${currentDateMarkKey}.moreOrEqualToFive`)
              })()
            }</span>
          </div>`
  
          return outputHtml + (index < dateMarks.length - 1 
            ? itemTemplate + `<span class="b-promobar__countdown-separator"></span>`
            : itemTemplate)
        }, '')
  
      }
  
      promobarCountdown.innerHTML = getResultHTML({ useSeconds, dateMarksMap: {
        day: 0,
        hour: 21,
        minute: 44,
        second: 59
      }})
  
      // const x = setInterval(() => {
      //   const now = new Date().getTime();
      //   const distance = countDownDate - now;
      //   const dateMarksMap = {
      //     day: Math.floor(distance / (1000 * 60 * 60 * 24)),
      //     hour: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      //     minute: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      //     second: Math.floor((distance % (1000 * 60)) / 1000),
      //   }
  
      //   promobarCountdown.innerHTML = getResultHTML({ useSeconds, dateMarksMap })
        
      //   if (distance < 0) {
      //     clearInterval(x);
      //     document.getElementsByClassName("js-b-promobar__countdown")[i].innerHTML = "EXPIRED";
      //   }
  
      // }, 1000);
    })

  },
}
